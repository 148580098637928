<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      {{ $t("components.ColorBySelect.title") }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-radio-group
        dense
        :value="groupAttribute"
        style="margin: 0"
        @change="setGroupAttribute"
      >
        <div
          v-for="element in radioElements"
          :key="element.value"
        >
          <v-tooltip
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-radio
                v-bind="attrs"
                :label="element.label"
                :value="element.value"
                v-on="on"
              />
            </template>
            <ColorTooltipContent :selected-group-attribute="element.value" />
          </v-tooltip>
        </div>
      </v-radio-group>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import GroupAttributes from "@/store/group_attributes";
import {color_map} from "@/graph/node_color";
import ColorTooltipContent from "@/components/sidemenu/ColorTooltipContent.vue";

export default {
  name: "ColorBySelect",
  components: {ColorTooltipContent},
  data() {
    return {
      colorByGroup: null,
      radioElements: [
        {value: GroupAttributes.GROUP_SUBJECT_AREA, label: this.$t("components.ColorBySelect.research_area")},
        {value: GroupAttributes.GROUP_SEX, label: this.$t("components.ColorBySelect.sex")},
      ]
    }
  },
  computed: {
    ...mapGetters({
      groupAttribute: "geoprofs/getGroupAttribute"
    })
  },
  methods: {
    ...mapActions({
      setGroupAttribute: "geoprofs/set_group_attribute"
    }),
  }
}
</script>

<style scoped>
</style>