<template>
  <div>
    <v-app-bar
      app
      color="dark"
      dark
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" />


      <v-spacer />

      <div class="d-flex align-center">
        <router-link
          to="/"
          @click.native="drawer = false"
        >
          <v-img
            alt="Geocite Logo"
            class="shrink mr-2"
            contain
            src="static/src/vue/dist/geocite_logo_white2.png"
            transition="scale-transition"
            width="100"
          />
        </router-link>
      </div>
      <v-progress-linear
        v-if="loading"
        :value="loading_pro"
        absolute
        bottom
      />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :disable-resize-watcher="true"
      app
    >
      <v-list nav>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Geocite
            </v-list-item-title>
            <v-list-item-subtitle>
              Navigation
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          @click="drawer = false"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "NavigationDrawer",
  data: () => ({
    drawer: false,
    items: [
      {title: 'Home', icon: 'mdi-home', to: {name: 'home'}},
      {title: 'About', icon: 'mdi-image', to: {name: 'about'}},
      {title: 'Komplettes Netzwerk', icon: 'mdi-graph-outline', to: {name: 'completeNetwork'}},
      {title: 'Netzwerk herunterladen', icon: 'mdi-download', to: {name: 'downloadNetwork'}},
    ]
  }),
  computed: {
    ...mapState({
      loading: state => state.loading.loading,
      loading_pro: state => state.loading.loading_progress
    }),
  }
}
</script>

<style scoped>

</style>