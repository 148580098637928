import * as d3 from "d3"

export const RELEVANT_NODE = 'relevant_node'
export const OTHER_NODE = 'other_node'
export const N_GROUPS = 5

export const color_map = {
    1: "#ffcb00", // Didaktik
    2: "#800080", // Geografie Gesamt
    3: "#008000", // Geoinformatik/Kartographie
    4: "#ff0000", // Humangeographie
    5: "#0000ff", // Physische Geografie

    6: "#e8cf8f", // Didaktik
    7: "#f8c0f8", // Geografie Gesamt
    8: "#aee7ae", // Geoinformatik/Kartographie
    9: "#f5b7b7", // Humangeographie
    10: "#c4c4f6", // Physische Geografie

    m: "#548231", //mediumpurple
    f: "#FDB000", //royalblue
    o: "#316682", //coral
    inactive: "#8d8d8d",
    [RELEVANT_NODE]: "#ffa500", //orange
    [OTHER_NODE]: "#0000cd", //mediumblue
}

export function getColorFunction({
                                     opacityFunction, // given a node id, returns a boolean if it should be opaque
                                 }) {
    return function (node) {
        let node_id = node.group;
        if (opacityFunction(node.id)) {
            node_id += N_GROUPS;
        }
        return d3.color(node.isActive ? color_map[node_id] : color_map['inactive'])
    }
}