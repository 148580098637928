import {default as axios} from "axios";

export async function get_geopof_attributes(page_nr){
        try {
        const response = await axios.get(`/api/geoprof/?page=${page_nr}`);
        return response.data
    } catch (error) {
        console.error(error);
    }
}
