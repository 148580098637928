import Vue from 'vue'
import Vuex from 'vuex'
import citations from "@/store/citations";
import geoprofs from "@/store/geoprofs";
import filter from "@/store/filter"
import loading from "@/store/loading";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    citations,
    geoprofs,
    filter,
    loading
  }
})
