export default {
    model: {
        prop: 'time',
        event: 'change'
    },
    props: {
        time: {
            type: Array,
            required: true
        }
    },
    computed: {
        maxSelected() {
            return [1940, new Date().getFullYear()]
        }
    },
    watch: {
        time: {
            handler() {
                this.setSelected()
            },
            immediate: true
        }
    },
}