<template>
  <table>
    <tr
      v-for="color in colors"
      :key="color.color"
    >
      <td>
        <div class="dot-div">
          <span
            class="dot"
            :style="{background: color.color}"
          />
        </div>
      </td>
      <td>
        {{ color.text }}
      </td>
    </tr>
  </table>
</template>

<script>
import {mapActions} from "vuex";
import GroupAttributes from "@/store/group_attributes";
import {color_map} from "@/graph/node_color";

export default {
  name: "ColorTooltipContent",
  props: {
    selectedGroupAttribute: {
      type: String, required: true
    }
  },
  computed:{
    colors(){
      return this.getColorsForGroupAttribute(this.selectedGroupAttribute)
    }
  },
  methods: {
    getColorsForGroupAttribute(groupAttribute) {
      switch (groupAttribute) {
        case GroupAttributes.GROUP_SEX:
          return [
            {color: color_map.m, text: this.$t("components.ColorTooltipContent.legend.m")},
            {color: color_map.f, text: this.$t("components.ColorTooltipContent.legend.f")},
            {color: color_map.o, text: this.$t("components.ColorTooltipContent.legend.o")}
          ]
        case GroupAttributes.GROUP_SUBJECT_AREA:
          return [
            {color: color_map[1], text: this.$t("components.ColorTooltipContent.legend.1")},
            {color: color_map[2], text: this.$t("components.ColorTooltipContent.legend.2")},
            {color: color_map[3], text: this.$t("components.ColorTooltipContent.legend.3")},
            {color: color_map[4], text: this.$t("components.ColorTooltipContent.legend.4")},
            {color: color_map[5], text: this.$t("components.ColorTooltipContent.legend.5")}
          ]
        default:
          return []
      }
    }
  }
}
</script>

<style scoped>
.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 auto;
}

.dot-div {
  display: grid;
  align-items: center;
  align-content: center;
}

td {
  padding-right: 8px;
}
</style>