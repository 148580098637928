<template>
  <v-tooltip
    top
    :value="true"
    v-bind="$attrs"
  >
    <v-img
      alt="Geocite Logo"
      class="shrink mr-2"
      contain
      src="static/src/vue/dist/geocite_logo_color.png"
      transition="scale-transition"
      width="100"
    />
    <v-switch
      :value="should_show_names"
      dark
      color="accent"
      :label="$t('views.CompleteNetworkView.tooltipShowNames')"
      @change="changeShouldShowNames"
    >
      <template #label>
        <span style="color: white">{{ $t('views.CompleteNetworkView.tooltipShowNames') }}</span>
      </template>
    </v-switch>
    <p class="mb-2">{{ $t('views.CompleteNetworkView.tooltipExplanation') }}</p>
    <ColorTooltipContent :selected-group-attribute="getGroupAttribute" />
  </v-tooltip>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ColorTooltipContent from "@/components/sidemenu/ColorTooltipContent.vue";

export default {
  name: "NetworkViewTooltip",
  components: {ColorTooltipContent},
  computed: {
    ...mapState({
      should_show_names: state => state.filter.should_show_names,
    }),
    ...mapGetters({
      getGroupAttribute: "geoprofs/getGroupAttribute"
    }),
  },
  methods: {
    ...mapActions({
      changeShouldShowNames: 'filter/setShouldShowNames',
    })
  }
}
</script>

<style scoped>
.v-tooltip__content {
  pointer-events: initial
}
</style>