import {get_all_citations, get_geoprof_citations} from "@/api/citations_api"

export default {
    namespaced: true,
    state: () => ({
        geoprof_citations: [],
    }),
    mutations: {
        remove_citations(state) {
            state.geoprof_citations = []
        },
        add_citations(state, citations) {
            state.geoprof_citations = citations
        },
    },
    actions: {
        async fetch_geoprof_citations({commit}, id) {
            commit('remove_citations')
            let result = await get_geoprof_citations(id)
            commit('add_citations', parse_citations(result))
        },
        async fetch_all_citations({commit}) {
            commit('remove_citations')
            let results = await get_all_citations()
            commit('add_citations', parse_citations(results))
        }
    },
    getters: {
        /**
         * Returns the currently relevant network links.
         * As the relevantIds are already filtered, we simply take all citations in this set initially.
         * If the citations are not extended than those additional citations need to be filtered out.
         * Otherwise, even if there is no filter set, the relevant ids are the whole set.
         *
         * @param state
         * @param getters
         * @param rootState
         * @param rootGetters
         * @returns {*[]} list of relevant citations with source and target node id.
         */
        getNetworkLinks(state, getters, rootState, rootGetters) {
            let relevantIds = rootGetters["filter/getRelevantIds"] // Only the relevant ids
            let relevantCitations = state.geoprof_citations.filter(citation => {
                return relevantIds.includes(citation.source) && relevantIds.includes(citation.target)
            })

            if (rootGetters["filter/shouldExtendRelevantIds"] && !rootGetters["filter/shouldExtendCitations"]) {
                let filteredIds = rootGetters["filter/getFilteredIds"] // Main ids that have precedent
                return relevantCitations.filter(citation => {
                    return filteredIds.includes(citation.source) || filteredIds.includes(citation.target)
                })
            }
            return relevantCitations
        },
        /**
         * Returns a reduced list of ids that have citations within the input set of node is.
         * I.e. only the ids remain that have a citation relation within the input set of nodes.
         *
         * @param state
         * @returns {function(*): any[]}
         */
        getReducedIdListFromCitations: (state) => (relevantIds) => {
            const mySet = new Set()
            state.geoprof_citations.forEach(citation => {
                if (relevantIds.includes(citation.source) && relevantIds.includes(citation.target)) {
                    mySet.add(citation.source)
                    mySet.add(citation.target)
                }
            })
            return Array.from(mySet)
        },
        /**
         * Returns an extended list including all node ids that directly cite the input set of node ids.
         *
         * @param state
         * @returns {function(*): any[]}
         */
        getExtendedIdListFromCitations: (state) => (relevantIds) => {
            const mySet = new Set()
            state.geoprof_citations.forEach(citation => {
                if (relevantIds.includes(citation.source) || relevantIds.includes(citation.target)) {
                    mySet.add(citation.source)
                    mySet.add(citation.target)
                }
            })
            if (state.should_show_unconnected_nodes) {
                relevantIds.forEach(relevantId =>{
                    mySet.add(relevantId);
                })
            }
            return Array.from(mySet)
        },
        get_citation_count_function(state, getters) {
            let citationMap = {}
            let min_count = Number.MAX_SAFE_INTEGER
            let max_count = 0
            getters.getNetworkLinks.forEach(citation => {
                let sourceValue = incrementValue(citationMap, citation.source)
                min_count = min_count <= sourceValue ? min_count : sourceValue
                max_count = max_count >= sourceValue ? max_count : sourceValue

                let targetValue = incrementValue(citationMap, citation.target)
                min_count = min_count <= targetValue ? min_count : targetValue
                max_count = max_count >= targetValue ? max_count : targetValue
            })

            return {
                citation_count_function: (node) => {
                    return node.id in citationMap ? citationMap[node.id] : 0;
                },
                max_count: max_count,
                min_count: min_count
            }
        }
    }
}

function parse_citations(citations) {
    return citations
        .filter(citation => citation.citing_actor !== citation.cited_actor)
        .map(citation => {
            return {
                source: citation.citing_actor,
                target: citation.cited_actor,
                value: citation.citation_count
            }
        })
}

function incrementValue(map, key) {
    map[key] = map[key] ? map[key] + 1 : 1
    return map[key]
}
