<template>
  <v-row
    class="fill-height"
    align-content="center"
    justify="center"
  >
    <v-col
      class="text-subtitle-1 text-center"
      cols="12"
    >
      {{ msg }}
    </v-col>
    <v-col cols="6">
      <v-progress-linear
        indeterminate
        rounded
        height="6"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LoadingNetwork",
  props: {
    msg: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>