const empty_filter = {
    sex: [],
    research_area: [],
    country: [],
    geoprof_ids: [],
    time: []
}

export default {
    namespaced: true,
    state: () => ({
        filter: {
            sex: [],
            research_area: [],
            country: [],
            geoprof_ids: [],
            time: [],
        },
        should_extend_relevant_ids: false,
        should_extend_citations: false,
        should_show_names: false,
        should_show_unconnected_nodes: false,
    }),
    mutations: {
        set_filter(state, filter) {
            state.filter = filter
        },
        remove_filter(state) {
            Object.assign(state.filter, empty_filter)
        },
        set_should_show_unconnected_nodes(state, shouldShowUnconnected) {
            state.should_show_unconnected_nodes = shouldShowUnconnected
        },
        set_should_extend_relevant_ids(state, shouldExtend) {
            state.should_extend_relevant_ids = shouldExtend
        },
        set_should_extend_citations(state, shouldExtend) {
            state.should_extend_citations = shouldExtend
        },
        set_should_show_names(state, shouldShowNames) {
            state.should_show_names = shouldShowNames
        },
    },
    actions: {
        removeFilter({commit}) {
            commit('remove_filter')
        },
        setFilter({commit, state}, filter) {
            commit('set_filter', filter)
        },
        setShouldExtendRelevantIds({commit}, shouldExtend) {
            commit('set_should_extend_relevant_ids', shouldExtend)
        },
        setShouldExtendCitations({commit}, shouldExtend) {
            commit('set_should_extend_citations', shouldExtend)
        },
        setShouldShowNames({commit}, shouldShowNames) {
            commit('set_should_show_names', shouldShowNames)
        },
        setShouldShowUnconnectedNodes({commit}, shouldShowUnconnected) {
            commit('set_should_show_unconnected_nodes', shouldShowUnconnected)
        }
    },
    getters: {
        getRelevantIds(state, getters, rootState, rootGetters) {

            let relevantIds = getters.getFilteredIds
            if (!rootState.should_show_unconnected_nodes) {
                relevantIds = rootGetters["citations/getReducedIdListFromCitations"](relevantIds)
            }
            if (getters.shouldExtendRelevantIds) {
                relevantIds = rootGetters["citations/getExtendedIdListFromCitations"](relevantIds)
            }
            return relevantIds
        },
        getFilteredIds(state, getters, rootState, rootGetters) {
            let attributes = rootGetters["geoprofs/getAttributes"]
            for (let key in state.filter) {
                let subFilter = state.filter[key]
                if (!subFilter.length) {
                    rootState.should_show_unconnected_nodes = false;
                    continue
                }

                if (key === "research_area") {
                    attributes = attributes.filter(attribute => subFilter.includes(attribute[key][0].id))
                } else if (key === 'geoprof_ids') {
                    rootState.should_show_unconnected_nodes = true;
                    attributes = attributes.filter(attribute => subFilter.includes(attribute.id))
                } else if (key === 'time') {
                    if (subFilter.length === 2) {
                        attributes = attributes.filter(attribute => isInTimeSpan(attribute, subFilter))
                    } else {
                        attributes = attributes.filter(attribute => isActiveBevorTimePoint(attribute, subFilter))
                    }
                } else {
                    attributes = attributes.filter(attribute => subFilter.includes(attribute[key]))
                }
            }
            return attributes.map(attribute => attribute.id)
        },
        shouldFilter(state) {
            return Object.entries(state.filter).some(subFilter => !!subFilter[1].length)
        },
        /**
         * If the user filters by specific nodes, we also want to display them if they have no citations.
         *
         * @param state vuex filter state
         * @returns {boolean} if nodes are explicitly selected
         */
        shouldShowUnconnectedNodes(state) {
            return state.should_show_unconnected_nodes
        },
        shouldExtendRelevantIds(state) {
            return state.should_extend_relevant_ids
        },
        shouldExtendCitations(state) {
            return state.should_extend_citations
        },
        shouldBeOpaque: (state, getters) => (id) => {
            return state.should_extend_relevant_ids && !getters.getFilteredIds.includes(id);
        },
        shouldShowNames(state) {
            return state.should_show_names
        }
    }
}

function isInTimeSpan(attribute, time) {
    let timeMin = time[0]
    let timeMax = time[1]
    let {activeMin, activeMax} = getActiveYears(attribute)
    return (!activeMin || timeMax >= activeMin) && (!activeMax || timeMin <= activeMax)
}

function isActiveBevorTimePoint(attribute, timePoint) {
    let {activeMin, _} = getActiveYears(attribute)
    return activeMin && activeMin <= timePoint
}

function getActiveYears(attribute) {
    return {
        activeMin: attribute['active_from'] ? new Date(Date.parse(attribute['active_from'])).getFullYear() : null,
        activeMax: attribute['active_to'] ? new Date(Date.parse(attribute['active_to'])).getFullYear() : null
    }
}