<template>
  <v-container fluid>
    <v-row
      v-if="loading"
      class="text-center"
    >
      <v-col cols="12">
        <LoadingNetwork :msg="$t('views.CompleteNetworkView.loadingMessage')" />
      </v-col>
    </v-row>
    <v-row
      v-else
      class="text-center"
    >
      <v-col>
        <NetworkViewTooltip attach="#network_canvas" />
        <NetworkCanvas id="network_canvas" />
        <SideMenuFAB />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import NetworkCanvas from "@/components/NetworkCanvas.vue";
import LoadingNetwork from "@/components/LoadingNetwork";
import SideMenuFAB from "@/components/sidemenu/SideMenuFAB.vue";
import NetworkViewTooltip from "@/components/NetworkViewTooltip.vue";

export default {
  name: "CompleteNetworkView",
  components: {NetworkViewTooltip, SideMenuFAB, NetworkCanvas, LoadingNetwork},
  data() {
    return {
      loading_network: true
    };
  },
  computed: {
    ...mapState({
      loading_geoprofs: state => state.loading.loading,
    }),
    loading() {
      return this.loading_network || this.loading_geoprofs
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      await this.$store.dispatch('geoprofs/set_research_area_attribute')
      await this.$store.dispatch('citations/fetch_all_citations')
      this.loading_network = false
    }
  }
}

</script>

<style scoped>

</style>