const axios = require('axios').default;

function get_citations() {
    return []
}

export async function get_geoprof_citations(id) {
    try {
        const response = await axios.get(`/api/citations/${id}`);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export async function get_all_citations(){
    try {
        const response = await axios.get('/api/citations')
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function get_citations_zip() {
    return await axios.get(`/api/citations/zip`, { responseType: 'blob' });
}

