<template>
  <v-range-slider
    v-model="selected"
    style="padding-top: 16px"
    :hint="$t('components.TimeFilterTab.hint')"
    :min="maxSelected[0]"
    :max="maxSelected[1]"
    thumb-label="always"
    persistent-hint
    @change="emitValue"
  />
</template>
<script>
import TimeFilterMixin from "@/components/sidemenu/filters/timeFilter/timeFilterMixin";

export default {
  name: "TimeRangeFilter",
  mixins: [TimeFilterMixin],
  data() {
    return {
      selected: [1949, 2022],
    }
  },
  methods: {
    setSelected() {
      if (this.time.length === 2) {
        this.selected = this.time
      } else {
        this.selected = this.maxSelected
      }
      if (this.time.length && this.time.length !== 2) {
        this.emitValue(this.selected)
      }
    },
    emitValue(event) {
      let emitEvent = event === this.maxSelected ? [] : event // If we select the max range no filter is necessary.
      this.$emit('change', emitEvent)
    }
  }
}
</script>

<style scoped>

</style>