<template>
  <v-container>
    <v-row>
      <v-checkbox
        v-model="selected"
        value="m"
        dense
        label="Männlich"
      />
    </v-row>
    <v-row>
      <v-checkbox
        v-model="selected"
        value="f"
        dense
        label="Weiblich"
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SexFilterTab",
  model: {
    prop: 'sex',
    event: 'change'
  },
  props: {
    sex: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selected: [],

    }
  },

  watch: {
    sex(newVal) {
      this.selected = newVal
    },

    selected(newVal) {
      this.$emit('change', newVal)
    }
  }
}
</script>

<style scoped>

</style>