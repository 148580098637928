<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      {{ $t("components.NodeSelectionToggle.title") }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      {{ $t('components.NodeSelectionToggle.explanation') }}

      <v-container>
        <v-row>
          <v-switch
            :input-value="shouldExtendRelevantIds"
            :disabled="!isFiltered"
            :label="$t('components.NodeSelectionToggle.title')"
            @change="setShouldExtend"
          />
        </v-row>
        <v-row>
          <v-switch
            :input-value="shouldExtendCitations"
            :disabled="!isFiltered"
            :label="$t('components.NodeSelectionToggle.extendCitationLabel')"
            @change="setShouldExtendCitations"
          />
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NodeSelectionToggle",
  computed: {
    ...mapGetters({
      isFiltered: "filter/shouldFilter",
      shouldExtendRelevantIds: "filter/shouldExtendRelevantIds",
      shouldExtendCitations: "filter/shouldExtendCitations"
    })
  },
  methods: {
    ...mapActions({
      setShouldExtendRelevantIds: "filter/setShouldExtendRelevantIds",
      setShouldExtendCitations: "filter/setShouldExtendCitations"
    }),
    setShouldExtend(event){
      this.setShouldExtendRelevantIds(event)
      if (!event){
        this.setShouldExtendCitations(event)
      }
    }
  }
}
</script>

<style scoped>

</style>