import {get_geopof_attributes} from "@/api/geoprof_api"
import GroupAttributes from "./group_attributes"
import {OTHER_NODE, RELEVANT_NODE} from "@/graph/node_color";

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        loading_progress: 0,
    }),
    mutations: {
        set_loading(state) {
            state.loading = true
        },
        remove_loading(state) {
            state.loading = false
        },
        set_loading_progress(state, progress) {
            state.loading_progress = progress
        },
    },
    actions:{
        start_loading({commit}){
            commit('set_loading')
            commit('set_loading_progress', 0)
        },
        end_loading({commit}){
            commit('remove_loading')
            commit('set_loading_progress', 0)
        },
        set_progress({commit}, {total_number, page_nr, page_size}){
            let progress = 0
            if (total_number > 1) {
                progress =  Math.floor(((page_nr * page_size) / total_number) * 100)
            }
            commit("set_loading_progress", progress)
        }
    }
}

