<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col
        md="8"
        sm="12"
        xs="12"
      >
        <v-card>
          <v-card-title class="justify-center">
            {{ $t("views.CitationDownloadView.title") }}
          </v-card-title>
          <v-card-text class="justify-center">
            {{ $t("views.CitationDownloadView.text") }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <LoadingNetwork
              v-if="loading"
              :msg="message"
            />

            <v-btn
              v-if="!loading"
              color="primary"
              @click="downloadFile()"
            >
              DownLoad
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import {get_citations_zip} from "@/api/citations_api";
import LoadingNetwork from "@/components/LoadingNetwork";

export default {
  name: "CitationDownloadView",
  components: {LoadingNetwork},
  data() {
    return {
      message: this.$t("views.CitationDownloadView.loadingMessage"),
      loading: false
    };
  },
  methods: {
    downloadFile() {
      this.loading = true
      get_citations_zip().then((res) => {
        console.log(res)
        let FILE = window.URL.createObjectURL(new Blob([res.data]));

        let docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', 'citations.zip');
        document.body.appendChild(docUrl);
        docUrl.click();
      }).catch((err) => {
            console.log(err)
          }
      ).finally(() => {
        this.loading = false
      });
    }
  }
}
</script>

<style scoped>

</style>