<template>
  <v-card
    flat
    outlined
    width="100%"
  >
    <v-card-title>{{ $t("components.GeoprofSelection.title") }}</v-card-title>
    <v-card-subtitle class="text-left font-italic">{{ $t("components.GeoprofSelection.explanation") }}</v-card-subtitle>
    <v-card-actions>
      <v-autocomplete
        :value="geoprof_ids"
        :items="items"
        multiple
        clearable
        chips
        deletable-chips
        @change="e => $emit('change', e)"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "GeoprofSelection",
  model: {
    prop: 'geoprof_ids',
    event: 'change'
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    geoprof_ids: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      model: null
    }
  },
  computed: {
    ...mapGetters({
      getGeoprofs: "geoprofs/getAttributes",
      shouldShowUnconnectedNodes: "filter/shouldShowUnconnectedNodes"
    }),
    items() {
      return this.getGeoprofs.map(geoprof => {
        return {
          text: `${geoprof.first_name} ${geoprof.last_name}`,
          value: geoprof.id
        }
      })
    }
  },
  methods: {
    ...mapActions({
      setShouldShowUnconnectedNodes: "filter/setShouldShowUnconnectedNodes",
    }),
    setShouldShow(event){

      this.setShouldShowUnconnectedNodes(event)
      if (!event){
        this.setShouldShowUnconnectedNodes(event)
      }
    }
  }

}
</script>

<style scoped>

</style>