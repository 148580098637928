import {get_geopof_attributes} from "@/api/geoprof_api"
import GroupAttributes from "./group_attributes"
import {OTHER_NODE, RELEVANT_NODE} from "@/graph/node_color";

export default {
    namespaced: true,
    state: () => ({
        geoprof_attributes: [],
        group_attribute: {},
        loading: false,
        loading_progress: 0,
    }),
    mutations: {
        remove_attributes(state) {
            state.geoprof_attributes = []
        },
        push_attributes(state, attributes) {
            state.geoprof_attributes.push(...attributes)
        },
        set_group_attribute(state, group_attribute) {
            state.group_attribute = group_attribute
        }
    },
    actions: {
        async fetch_all_geoprofs({commit, dispatch}) {
            commit('remove_attributes')
            dispatch('loading/start_loading', null, {root: true})
            let page_nr = 0
            let result = {next: true}
            while (result.next) {
                page_nr += 1
                result = await get_geopof_attributes(page_nr)
                commit('push_attributes', result.results)
                dispatch('loading/set_progress',
                    {total_number: result.count, page_nr: page_nr, page_size: result.results.length},
                    {root: true})
            }
            dispatch('loading/end_loading', null, {root: true})
        },
        set_person_network_attribute({commit}, id) {
            commit('set_group_attribute', {'type': GroupAttributes.GROUP_PERSON, 'id': id})
        },
        set_research_area_attribute({commit}) {
            commit('set_group_attribute', {'type': GroupAttributes.GROUP_SUBJECT_AREA})
        },
        set_group_attribute({commit}, group_attribute) {
            commit('set_group_attribute', {'type': group_attribute})
        }
    },
    getters: {
        getNodeTitle: (state) => (id) => {
            let attribute = state.geoprof_attributes.find(attribute => attribute.id === id)
            return `${attribute.first_name} ${attribute.last_name}
                    ${attribute.research_area[0].name}
                    ${getActiveFromText(attribute)}`
        },
        getNodeNameFunction: (state) => {
            let map = {}
            state.geoprof_attributes.forEach(attribute => map[attribute.id] = `${attribute.first_name} ${attribute.last_name}`);
            return (node) => map[node.id];
        },
        getNetworkNodes(state, getters) {
            let relevant_attributes = getters.getFilteredAttributes
            switch (state.group_attribute.type) {
                case GroupAttributes.GROUP_PERSON:
                    return map_group_person_attribute(relevant_attributes, state.group_attribute.id)
                case GroupAttributes.GROUP_SUBJECT_AREA:
                    return map_group_subject_attribute(relevant_attributes)
                case GroupAttributes.GROUP_SEX:
                    return map_group_sex_attribute(relevant_attributes)
            }
        },
        getAttributes(state) {
            return state.geoprof_attributes
        },
        getFilteredAttributes(state, getters, rootState, rootGetters) {
            let relevant_ids = rootGetters["filter/getRelevantIds"]
            return state.geoprof_attributes.filter(attribute => relevant_ids.includes(attribute.id))
        },
        getGroupAttribute(state) {
            return state.group_attribute.type
        }
    }
}

function getActiveFromText(attribute) {
    let year_from = new Date(attribute.active_from)
    if (attribute.active_to) {
        let year_to = new Date(attribute.active_to)
        return `Professur seit ${year_from.getFullYear()} bis ${year_to.getFullYear()}`;
    } else {
        return `Professur seit ${year_from.getFullYear()}`;
    }
}

function get_progress(total_number, page_nr, page_size) {
    if (total_number < 1) {
        return 0
    }
    return Math.floor(((page_nr * page_size) / total_number) * 100)
}

function map_group_person_attribute(attributes, id) {
    return attributes.map(attribute => {
        return {
            'id': attribute.id,
            'group': attribute.id === id ? RELEVANT_NODE : OTHER_NODE,
            'linkedNodes': [],
            'isActive': true,
        }
    })
}

function map_group_subject_attribute(attributes) {
    return attributes.map(attribute => {
        return {
            'id': attribute.id,
            'group': attribute.research_area[0].id,
            'linkedNodes': [],
            'isActive': true,
        }
    })
}

function map_group_sex_attribute(attributes) {
    return attributes.map(attribute => {
        return {
            'id': attribute.id,
            'group': attribute.sex,
            'linkedNodes': [],
            'isActive': true,
        }
    })
}