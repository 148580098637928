<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      {{ $t("components.DownloadFilteredNetwork.title") }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="text-left">
      <p class="mb-2">{{ $t('components.DownloadFilteredNetwork.text') }}</p>
      <ul>
        <li>{{ $t('components.DownloadFilteredNetwork.data1') }}</li>
        <li>{{ $t('components.DownloadFilteredNetwork.data2') }}</li>
      </ul>
    </v-expansion-panel-content>
    <v-expansion-panel-content>
      <v-container>
        <LoadingNetwork
          v-if="loading"
          :msg="message"
        />

        <v-btn
          v-if="!loading"
          @click="downloadFiles()"
        >
          DownLoad
        </v-btn>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingNetwork from "@/components/LoadingNetwork.vue";

export default {
  name: "DownloadFilteredNetwork",
  components: {LoadingNetwork},
  data() {
    return {
      message: this.$t("components.DownloadFilteredNetwork.loadingMessage"),
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      getFilteredAttributes: 'geoprofs/getFilteredAttributes',
      getNetworkLinks: 'citations/getNetworkLinks',
    })
  },
  methods: {
    downloadFiles() {
      this.loading = true;
      let attribute = this.createAttributesCsv();
      let citations = this.createCitationsCsv();
      Promise.all([attribute, citations]).catch(err => {
        console.error(err)
      }).finally(() => {
        this.loading = false
      })
    },
    async createAttributesCsv() {
      let csv = "id, first_name, last_name, sex, is_alive, subject\n";
      this.getFilteredAttributes.forEach(attribute => {
        csv += `${attribute.id}, ${attribute.first_name}, ${attribute.last_name}, ${attribute.sex}, ${attribute.is_alive}, ${attribute.research_area[0].name}`;
        csv += "\n";
      })
      this.makeDownloadCSV(csv, 'attributes.csv')
    },
    async createCitationsCsv() {
      let csv = "Citing, Cited, Citation Count\n";
      this.getNetworkLinks.forEach(link => {
        csv += `${link.source}, ${link.target}, ${link.value},`;
        csv += "\n";
      })
      this.makeDownloadCSV(csv, 'citations.csv')
    },
    makeDownloadCSV(text, filename) {
      let hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(text);
      hiddenElement.target = '_blank';

      //provide the name for the CSV file to be downloaded
      hiddenElement.download = filename;
      hiddenElement.click();
    }
  },
}

</script>

<style scoped>

</style>