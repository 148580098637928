<template>
  <v-container>
<!--    <v-row>-->
<!--      <v-checkbox-->
<!--        v-model="isRange"-->
<!--        :hint="$t('components.TimeFilterTab.checkboxHint')"-->
<!--        :label="$t('components.TimeFilterTab.checkbox')"-->
<!--      />-->
<!--    </v-row>-->
    <v-row>
      <v-col>
        <TimeRangeFilter
          :time="time"
          @change="e => $emit('change', e)"
        />
<!--        <TimePointFilter-->
<!--          v-else-->
<!--          :time="time"-->
<!--          @change="e => $emit('change', e)"-->
<!--        />-->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TimePointFilter from "@/components/sidemenu/filters/timeFilter/TimePointFilter.vue";
import TimeRangeFilter from "@/components/sidemenu/filters/timeFilter/TimeRangeFilter.vue";

export default {
  name: "TimeFilterTab",
  components: {TimeRangeFilter,},
  model: {
    prop: 'time',
    event: 'change'
  },
  props: {
    time: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isRange: false,
    }
  },
}
</script>

<style scoped>

</style>