import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueMatomo from 'vue-matomo'

Vue.config.productionTip = false

Vue.use(VueMatomo, {
        host: 'https://geocite.de/analytics/',
        siteId: 1,
        router: router,
    });

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

window._paq.push(['trackPageView']); //To track pageview
