<template>
  <div>
    <v-fab-transition>
      <v-btn
        color="primary"
        fab
        fixed
        top
        right
        large
        style="transform: translateY(100%)"
        @click.stop="filterDrawer = !filterDrawer"
      >
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-navigation-drawer
      v-model="filterDrawer"
      hide-overlay
      fixed
      temporary
      right
      clipped
      :width="filterDrawerWidth"
    >
      <v-col>
        <v-row>
          <v-list-item style="padding: 0">
            <v-list-item-avatar
              :width="filterDrawerWidth / 5"
              rounded="0"
            >
              <v-img
                alt="Geocite Logo"
                class="shrink mr-2"
                contain
                src="static/src/vue/dist/geocite_logo_color.png"
                transition="scale-transition"
                width="100"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h6 text-left">
                {{ $t("components.SideMenuFAB.title") }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                @click.stop="filterDrawer = !filterDrawer"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-row>
        <v-row>
          <network-filter />
        </v-row>
        <v-row>
          <v-expansion-panels
            style="width: 100%"
            accordion
          >
            <color-by-select />
            <node-selection-toggle />
            <download-filtered-network />
          </v-expansion-panels>
        </v-row>
      </v-col>
    </v-navigation-drawer>
  </div>
</template>

<script>
import NetworkFilter from "@/components/sidemenu/filters/NetworkFilter.vue";
import ColorBySelect from "@/components/sidemenu/ColorBySelect.vue";
import NodeSelectionToggle from "@/components/sidemenu/NodeSelectionToggle.vue";
import DownloadFilteredNetwork from "@/components/sidemenu/DownloadFilteredNetwork.vue";

export default {
  name: "SideMenuFAB",
  components: {DownloadFilteredNetwork, NodeSelectionToggle, ColorBySelect, NetworkFilter},
  data() {
    return {
      filterDrawer: false,
      filterDrawerWidth: 600
    };
  }
}
</script>

<style scoped>

</style>