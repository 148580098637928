const GROUP_PERSON = 'GROUP_PERSON'
const GROUP_SEX = 'GROUP_SEX'
const GROUP_SUBJECT_AREA = 'GROUP_SUBJECT_AREA'
const GROUP_COUNTRY = 'GROUP_COUNTRY'
const GROUP_UNIVERSITY = 'GROUP_UNIVERSITY'


export default {
    GROUP_PERSON: GROUP_PERSON,
    GROUP_SEX: GROUP_SEX,
    GROUP_SUBJECT_AREA: GROUP_SUBJECT_AREA,
    GROUP_COUNTRY: GROUP_COUNTRY,
    GROUP_UNIVERSITY: GROUP_UNIVERSITY
}