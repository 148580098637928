<template>
  <div
    id="network_canvas"
    v-resize="resizeHandler"
    style="max-height: 100%;max-width:100%;"
  >
    <v-tooltip
      top
      :value="tooltip.text"
      :position-x="tooltip.position_x"
      :position-y="tooltip.position_y"
    >
      <span style="white-space: pre-line">
        {{ tooltip.text }}
      </span>
    </v-tooltip>
    <canvas
      id="chart"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ForceGraph, GRAPH_DEFAULTS} from "@/graph/force_graph";
import {getColorFunction} from "@/graph/node_color";

export default {
  name: "NetworkCanvas",
  data() {
    return {
      width: 1920,
      height: 1080,
      end: null,
      simulationInvalidation: null,
      context: null,
      tooltip: {
        text: null,
        position_x: null,
        position_y: null
      }
    };
  },
  computed: {
    ...mapGetters({
      getNetworkNodes: 'geoprofs/getNetworkNodes',
      getNetworkLinks: 'citations/getNetworkLinks',
      getNodeTitle: 'geoprofs/getNodeTitle',
      shouldBeOpaque: 'filter/shouldBeOpaque',
      shouldShowNames: 'filter/shouldShowNames',
      get_citation_count_function: "citations/get_citation_count_function",
      nodeNameFunction: "geoprofs/getNodeNameFunction"
    })
  },
  watch: {
    getNetworkLinks() {
      this.draw_graph()
    },
    getNetworkNodes() {
      this.draw_graph()
    },
    shouldShowNames() {
      this.draw_graph()
    }
  },
  async mounted() {
    this.setCanvasSize()
    const canvas = document.getElementById('chart');
    this.context = canvas.getContext('2d');
    this.draw_graph()
  },
  async beforeDestroy() {
    this.simulationInvalidation()
  },
  methods: {
    draw_graph() {
      if (this.simulationInvalidation) this.simulationInvalidation()
      let nodes = this.getNetworkNodes
      let links = this.getNetworkLinks
      let colorFunction = getColorFunction({
        opacityFunction: this.shouldBeOpaque
      })
      ForceGraph(this.context, this.nodeClickHandler,
          {nodes, links}, {
            nodeRadiusFunction: this.getNodeRadiusFunction(),
            colorFunction: colorFunction,
            nodeNameFunction: this.shouldShowNames ? this.nodeNameFunction : null,
            width: this.width,
            height: this.height,
            invalidation: this.get_invalidation_promise()
          })
    },
    get_invalidation_promise() {
      return new Promise((resolve, _) => {
        // We save the resolve function to call in the beforeDestroy method later on.
        this.simulationInvalidation = resolve;
      })
    },
    nodeClickHandler(node, event) {
      if (node) {
        this.tooltip.text = this.getNodeTitle(node.id)
        this.tooltip.position_x = event.clientX
        this.tooltip.position_y = event.clientY
      } else {
        this.tooltip.text = null
      }
    },
    setCanvasSize() {
      const div = document.getElementById('network_canvas')

      // This is a hack at best
      this.height = window.innerHeight - 100
      // this.width = window.innerWidth
      //this.height = div.offsetHeight//Math.min(1080, div.offsetHeight)
      this.width = div.offsetWidth//Math.min(1920, div.offsetWidth)
    },
    resizeHandler() {
      //const div = document.getElementById('network_canvas')
      //if (this.context && (Math.abs(div.offsetHeight - this.height) > 50 || Math.abs(div.offsetWidth - this.width) > 50)) {
      this.setCanvasSize()
      this.draw_graph()
      //}
    },
    getNodeRadiusFunction() {
      const {citation_count_function, max_count, min_count} = this.get_citation_count_function

      return (node) => {
        let citation_count = citation_count_function(node)
        let percentage = (citation_count - min_count) / (max_count - min_count);
        percentage = Math.min(Math.max(isNaN(percentage) ? 0 : percentage, 0), 1);
        return (percentage * (GRAPH_DEFAULTS.maxNodeRadius - GRAPH_DEFAULTS.minNodeRadius)) + GRAPH_DEFAULTS.minNodeRadius // Scale between [5,20]
      }

    }
  }
}
</script>

<style scoped>
.v-tooltip__content {
  pointer-events: initial;
}
</style>