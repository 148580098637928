<template>
  <v-container>
    <!-- We access the research area through integer ids -->
    <v-row
      v-for="n in 5"
      :key="n"
    >
      <v-checkbox
        v-model="selected"
        :value="n"
        :label="research_area_names[n]"
        dense
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ResearchAreaFilterTab",
  model: {
    prop: 'research_area',
    event: 'change'
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    research_area: {
      type: Array,
      required: true
    }

  },
  data() {
    return {
      selected: [],
      research_area_names: {
        1: "Didaktik",
        2: "Geographie Gesamt",
        3: "Geoinformatik/Kartographie",
        4: "Humangeographie",
        5: "Physische Geographie"
      }
    }
  },

  watch: {
    research_area(newVal) {
      this.selected = newVal
    },

    selected(newVal) {
      this.$emit('change', newVal)
    }
  }
}
</script>

<style scoped>

</style>