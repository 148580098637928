<template>
  <v-slider
    v-model="selected"
    :min="maxSelected[0]"
    :max="maxSelected[1]"
    thumb-label="always"
    persistent-hint
    @change="emitValue"
  />
</template>
<script>
import TimeFilterMixin from "@/components/sidemenu/filters/timeFilter/timeFilterMixin";

export default {
  name: "TimePointFilter",
  mixins: [TimeFilterMixin],
  data() {
    return {
      selected: null,
    }
  },

  methods: {
    setSelected() {
      if (this.time.length === 1) {
        this.selected = this.time[0]
      } else {
        this.selected = null
      }
      if (this.time.length && this.time.length === 2) {
        this.emitValue(this.selected)
      }
    },
    emitValue(event) {
      let emitEvent = event ? [event] : []
      this.$emit('change', emitEvent)
    }
  }
}
</script>

<style scoped>

</style>