<template>
  <v-container>
    <v-row>
      <geoprof-selection v-model="filter.geoprof_ids" />
    </v-row>
    <v-row>
      <v-card
        flat
        outlined
        width="100%"
      >
        <v-card-title>{{ $t("components.NetworkFilter.title") }}</v-card-title>
        <v-tabs
          v-model="tab"
        >
          <v-tabs-slider color="primary" />

          <v-tab
            v-for="item in items"
            :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item"
          >
            <v-card flat>
              <v-card-actions>
                <sex-filter-tab
                  v-if="item === 'Geschlecht'"
                  v-model="filter.sex"
                />
                <research-area-filter-tab
                  v-else-if="item === 'Subdisziplin'"
                  v-model="filter.research_area"
                />
                <time-filter-tab
                  v-else-if="item === 'Zeitraum'"
                  v-model="filter.time"
                />
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                :color="shouldFilter ? 'warning' : 'secondary'"
                v-on="on"
                @click="$store.dispatch('filter/removeFilter')"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("components.NetworkFilter.deleteBtn") }}</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import ResearchAreaFilterTab from "@/components/sidemenu/filters/ResearchAreaFilterTab.vue";
import SexFilterTab from "@/components/sidemenu/filters/SexFilterTab.vue";
import TimeFilterTab from "@/components/sidemenu/filters/TimeFilterTab.vue";
import GeoprofSelection from "@/components/sidemenu/filters/GeoprofSelection.vue";
import {mapGetters} from "vuex";

export default {
  name: "NetworkFilter",
  components: {GeoprofSelection, TimeFilterTab, SexFilterTab, ResearchAreaFilterTab},
  data() {
    return {
      tab: null,
      items: ['Geschlecht', 'Zeitraum', 'Subdisziplin'],
      filter: {
        sex: [],
        research_area: [],
        country: [],
        time: [],
        geoprof_ids: []
      },
    }
  },
  computed: {
    ...mapGetters({
      shouldFilter: 'filter/shouldFilter'
    })
  },
  watch: {
    filter: {
      handler(new_filter, _) {
        this.$store.dispatch("filter/setFilter", new_filter)
      },
      deep: true
    }
  },
  methods: {}
}


let test = {
  "id": 1,
  "first_name": "Gerhard",
  "middle_name": null,
  "last_name": "Abele",
  "former_name": null,
  "nickname": null,
  "suffix": null,
  "sex": "männlich",
  "is_alive": false,
  "research_area": [
    {
      "id": 2,
      "name": "Geographie Gesamt"
    }
  ],
  "picture_source": null
}
</script>

<style scoped>

</style>

