import * as d3 from "d3"

export const GRAPH_DEFAULTS = {
    nodeStroke: "#999", // node stroke color
    minNodeRadius: 5,  // min node radius, in pixels
    maxNodeRadius: 20, // max node radius, in pixels
    minLinkWidth: 0.05,
    maxLinkWidth: 1,
    linkQuantileCutoff: 0.95,
    linkStrokeActive: "#999", // link stroke color
    linkStrokeFocused: "#000",
    linkStrokeInactive: "#d7d7d7",
}


export function ForceGraph(context,
                           clickHandler, // called with the id of a clicked node
                           {
                               nodes, // an iterable of node objects ([{id}, …])
                               links // an iterable of link objects ([{source, target}, …])
                           },
                           {
                               nodeRadiusFunction, // given n in nodes returns a radius
                               colorFunction, // given n in nodes, returns a color
                               nodeNameFunction, // given n in nodes returns a name
                               width = 640, // outer width, in pixels
                               height = 400, // outer height, in pixels
                               invalidation // when this promise resolves, stop the simulation
                           } = {}) {

    //CanvasRenderingContext2D.prototype.clip = function () { };
    let nodeMap = {};
    nodes.forEach((element) => {
        nodeMap[element.id] = element;
    });

    // add values of links, if there are bidirectional citations and remove additional links
    let linkMap = {};
    links.forEach((element) => {
        let inverseKey = element.target.toString() + '-' + element.source.toString();
        let key = element.source.toString() + '-' + element.target.toString();
        if (inverseKey in linkMap) {

            // currently bugged, since we add values each iteration -> solve bug somewhere else
            //linkMap[inverseKey].value += element.value;
            linkMap[key] = element;
        } else {
            linkMap[key] = element;
        }
        if (nodeMap[element.source].linkedNodes.indexOf(element.target) === -1) {
            nodeMap[element.source].linkedNodes.push(element.target);
        }
        if (nodeMap[element.target].linkedNodes.indexOf(element.source) === -1) {
            nodeMap[element.target].linkedNodes.push(element.source);
        }
    });
    nodes = Object.keys(nodeMap).map(function(k){return nodeMap[k]});
    links = Object.keys(linkMap).map(function(k){return linkMap[k]});

    // Replace the input nodes and links with mutable objects for the simulation.
    let linkArray = d3.map(links, l => l.value)
    let q3 = d3.quantile(linkArray, GRAPH_DEFAULTS.linkQuantileCutoff)
    linkArray = d3.map(linkArray, v => Math.min(v, q3));
    //let linkStrokeWidthMap = robustScaling(nodeArray);
    let linkStrokeWidthMap = linkArray.minmaxScaling(GRAPH_DEFAULTS.minLinkWidth, GRAPH_DEFAULTS.maxLinkWidth)

    links = d3.map(links, (link, _) => ({source: link.source, target: link.target}));

    nodeRadiusFunction = nodeRadiusFunction ? nodeRadiusFunction : () => GRAPH_DEFAULTS.minNodeRadius


    // Construct the forces.
    const forceNode = d3.forceManyBody();
    const forceLink = d3.forceLink(links).id((node) => node.id);
    let k = Math.sqrt(nodes.length / (width * height));
    //let nodeStrength = calculate_node_force(nodes.length);
    forceNode.strength(-2 / k);



    let {force_x, force_y} = calculate_force(width, height, 0.05)
    const simulation = d3.forceSimulation(nodes)
        .force("link", forceLink)
        .force("charge", forceNode)
        .force("center", d3.forceCenter().x(width / 2).y(height / 2))
        .force("collide", d3.forceCollide().radius(nodeRadiusFunction).strength(2))
        .force("top", d3.forceY().strength(force_y))
        .force("bottom", d3.forceY().y(height).strength(force_y))
        .force("left", d3.forceX().strength(force_x))
        .force("right", d3.forceX().x(width).strength(force_x))
        .on("tick", ticked);
    simulation.alphaDecay(0.01);


    let selectedSubject = null

    let drawLinks = getDrawLinksFunction(context, links, linkStrokeWidthMap)
    let drawNodes = getDrawNodesFunction(context, nodes, nodeRadiusFunction, colorFunction, nodeNameFunction, width, height)

    let transform = d3.zoomIdentity;

    function ticked() {
        for (let i = 0; i < 2; i++) {
            simulation.tick();
        }
        context.clearRect(0, 0, width, height);
        drawLinks(selectedSubject, transform);
        drawNodes(selectedSubject, transform);
    }

    if (invalidation != null) invalidation.then(() => simulation.stop());


    // Choose the circle that is closest to the pointer for dragging.
    function findSubject(event) {
        let x = transform.invertX(event.sourceEvent ? event.sourceEvent.offsetX : event.x)
        let y = transform.invertY(event.sourceEvent ? event.sourceEvent.offsetY : event.y)

        let subject = null;
        let distance = GRAPH_DEFAULTS.maxNodeRadius;
        for (const node of nodes) {
            let d = Math.hypot(x - node.x, y - node.y);
            if (d < distance) {
                distance = d;
                subject = node;
            }
        }
        return subject;
    }

    function drag(simulation) {
        function dragstarted(event) {
            selectedSubject = event.subject
            if (!event.active) simulation.alphaTarget(0.3).restart();
            event.subject.fx = event.subject.x;
            event.subject.fy = event.subject.y;
        }

        function dragged(event) {
            if (clickHandler) clickHandler(null) // disable tooltip

            event.subject.fx = event.x;
            event.subject.fy = event.y;
        }

        function dragended(event) {
            selectedSubject = null
            if (!event.active) simulation.alphaTarget(0);
            event.subject.fx = null;
            event.subject.fy = null;
        }

        return d3.drag()
            .subject(findSubject)
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);
    }

    function zoom(simulation) {

        function zoomed(event) {
            transform = event.transform;

            // freeze without simulation restart
            simulation.restart();
        }

        return d3.zoom()
          .extent([[0, 0], [width, height]])
          .scaleExtent([1/2, 16])
          .on("zoom", zoomed);
    }

    let selection = d3.select(context.canvas).call(drag(simulation)).call(zoom(simulation))

    if (clickHandler) selection.on("click", (event) => {
        let subject = findSubject({x: event.offsetX, y: event.offsetY})
        selectedSubject = null

        clickHandler(subject, event)
        simulation.restart();

        if (!subject) return

        selectedSubject = subject
    })

    return selection
}

function getDrawLinksFunction(context, links, linkStrokeWidthMap) {
    function drawLinks(selectedSubject, transform) {
        context.save();
        context.translate(transform.x, transform.y);
        context.scale(transform.k, transform.k);
        let topLayerLinks = [];
        for (const [i, link] of links.entries()) {

            context.strokeStyle = selectedSubject ? GRAPH_DEFAULTS.linkStrokeInactive : GRAPH_DEFAULTS.linkStrokeActive;
            if (selectedSubject && ((selectedSubject.id === link.source.id) || (selectedSubject.id === link.target.id))) {
                topLayerLinks.push({'linewidth': linkStrokeWidthMap[i], 'link': link});
                continue;
            }
            context.beginPath();
            context.moveTo(link.source.x, link.source.y);
            context.lineTo(link.target.x, link.target.y);
            context.lineWidth = linkStrokeWidthMap[i];
            context.stroke();
        }
        topLayerLinks.forEach(element => {
            context.beginPath();
            context.moveTo(element.link.source.x, element.link.source.y);
            context.lineTo(element.link.target.x, element.link.target.y);
            context.strokeStyle = GRAPH_DEFAULTS.linkStrokeFocused;
            context.lineWidth = element.linewidth;
            context.stroke();
        })
        context.restore();
    }

    return drawLinks
}

function getDrawNodesFunction(context, nodes, nodeRadiusFunction, colorFunction, nodeNameFunction, width, height) {

    function drawNodes(selectedSubject, transform) {
        context.save();
        context.translate(transform.x, transform.y);
        context.scale(transform.k, transform.k);
        for (const [_, node] of nodes.entries()) {
            context.strokeStyle = GRAPH_DEFAULTS.nodeStroke;
            if (selectedSubject && selectedSubject.id === node.id) {
                context.strokeStyle = "#000"
            }
            if (selectedSubject && !selectedSubject.linkedNodes.includes(node.id)) {
                node.isActive = false;
            }
            if (selectedSubject && (selectedSubject.linkedNodes.includes(node.id) || (selectedSubject.id === node.id))) {
                node.isActive = true;
            }
            if (!selectedSubject) {
                node.isActive = true;
            }
            context.fillStyle = colorFunction(node)
            context.beginPath();
            let nodeRadius = nodeRadiusFunction(node)
            context.moveTo(node.x + nodeRadius, node.y);
            context.arc(node.x, node.y, nodeRadius, 0, 2 * Math.PI);
            context.fill();
            context.stroke();
        }
        for (const [_, node] of nodes.entries()) {
            let nodeRadius = nodeRadiusFunction(node)
            if (nodeNameFunction){
                context.fillStyle = "#000";
                if (selectedSubject && (!selectedSubject.linkedNodes.includes(node.id) && !(selectedSubject.id === node.id))) {
                    context.fillStyle = "#8d8d8d";
                }
                context.fillText(nodeNameFunction(node), node.x-(nodeRadius + 1), node.y-(nodeRadius+1));
                context.stroke();
            }
        }

        context.restore();
    }
    return drawNodes
}


/**
 * Calculates a scaling for the force depending on the aspect ratio of height and width.
 * We want the forces to scale depending on the view port. This is done to center the nodes in the view port.
 *
 * @param width width of the canvas
 * @param height height of the canvas
 * @param starting_force force value that we want to scale
 * @returns {{force_x: number, force_y: number}} scaled forces that apply from the x and y direction.
 */
function calculate_force(width, height, starting_force) {
    let ratio_x = height / width
    let ratio_y = width / height
    return {
        force_x: starting_force * ratio_x,
        force_y: starting_force * ratio_y
    }
}

/**
 * Calculates a node repulsion force depending on the number of nodes.
 * The fewer nodes, the higher the force, as we want to display more nodes closer together.
 *
 * @param number_of_nodes
 * @returns {number} repulsion force between [-50, -150]
 */
function calculate_node_force(number_of_nodes) {
    let max_number = Math.max(number_of_nodes, 1100) // safe maximum
    let ratio = (max_number - number_of_nodes) / max_number
    let force = ((400 - 50) * ratio + 50) // scale between range [50, 150]
    return force * -1 // repulsion force needed
}

function robustScaling(data) {
    let med = d3.median(data);
    let q1 = d3.quantile(data, 0.25);
    let q3 = d3.quantile(data, 0.75);
    return data.map(num => (num - med) / (q3 - q1));
}

function zScoreScaling(data) {
    let mean = d3.mean(data);
    let std = d3.deviation(data);
    return data.map(num => (num - mean) / std);
}

 Array.prototype.minmaxScaling = function(scaledMin, scaledMax) {
      let max = Math.max.apply(Math, this);
      let min = Math.min.apply(Math, this);
      return this.map(num => (scaledMax-scaledMin)*(num-min)/(max-min)+scaledMin);
    }
