<template>

  <div class="d-flex justify-center">


    <div class="home d-flex flex-column justify-center ma-4 pa-4">

      <div class="geocite-logo d-flex justify-space-between mb-6">
        <a href="https://www.dfg.de/">
          <img
            alt="DFG logo"
            src="static/src/vue/dist/dfg.png"
          >
        </a>

        <a href="https://geographische-netzwerkstatt.uni-passau.de/start/?lang=de/">
          <img
            alt="GEOcite logo"
            src="static/src/vue/dist/geocite_logo.png"
          >
        </a>
      </div>

      <div class="mb-4">
        <h1>Willkommen zu <span class="geocite-orange"><b>GEO</b></span><span class="geocite-blue"><i>cite</i></span> <b><i>Online</i></b> </h1>

        <p><span class="geocite-orange"><b>GEO</b></span><span class="geocite-blue"><i>cite</i></span> <b><i>Online</i></b> ist ein webbasiertes Software-Tool zur bibliometrischen
          Wissenschaftsbeobachtung in der deutschsprachigen Geographie. Das Programm entstand im Rahmen des DFG-Projekts
          "Die Säulen der Einheit und die Brücken im Fach: Geographische Forschung zwischen Rhetorik und Praxis“
          (Förderzeitraum September 2013 bis Juli 2023; Leitung: Prof. Dr. Malte Steinbrink).
          <span class="geocite-orange"><b>GEO</b></span><span class="geocite-blue"><i>cite</i></span> <b><i>Online</i></b> ermöglicht die Visualisierung und Analyse umfangreicher historischer und
          aktueller Zitationsdaten.</p>
      </div>

      <div class="geocite-trynow d-flex justify-center mb-4">
        <router-link :to="{name: 'completeNetwork'}">
          <img
            alt="GEOcite try now"
            src="static/src/vue/dist/trynow.png"
          >
        </router-link>
      </div>

      <div class="mb-4">
        <h2>Warum <span class="geocite-orange"><b>GEO</b></span><span class="geocite-blue"><i>cite</i></span> <b><i>Online</i></b></h2>

        Mit <span class="geocite-orange"><b>GEO</b></span><span class="geocite-blue"><i>cite</i></span> <b><i>Online</i></b> können Sie on screen…
        <ul>
          <li>Zitationsmuster, Trends und wichtige Autor*innen in der Geographie identifizieren</li>
          <li>Egonetzwerke aktueller und früherer Geographie-Professor*innen visualisieren</li>
          <li>die Entwicklung bestimmter Themen, Konzepte oder Theorien im Zeitverlauf nachverfolgen</li>
          <li>die Paradigmengenese und -evolution analysieren</li>
          <li>sozialer Strukturen, Ungleichheiten und deren Dynamiken innerhalb der geographischen Professor*innenschaft
            (z. B. Geschlechterungleichheiten) untersuchen</li>
        </ul>
        <p>Mithilfe der benutzerfreundlichen Online-Oberfläche lassen sich individuelle Netzwerkanalysen durchführen.
          Passen Sie Ihre Analyse mit den angebotenen Filtern an Ihre spezifischen Interessen und Forschungsfragen an und
          erhalten Sie in direkt aussagekräftige Ergebnisse. Vorkenntnisse in der Sozialen Netzwerkanalyse sind nicht
          erforderlich.
        </p>

        <p>Sämtliche Daten stehen ihnen zur Weiterverarbeitung oder zur Nutzung mit anderen Netzwerkanalyseprogrammen als
          csv-Dateien zum Download zur Verfügung.
        </p>
      </div>

      <div class="mb-4">
        <h2>Datengrundlage von <span class="geocite-orange"><b>GEO</b></span><span class="geocite-blue"><i>cite</i></span> <b><i>Online</i></b></h2>
        <p>Auf der Website dargestellt werden die Zitationsbeziehungen zwischen allen Professor*innen der Geographie im
          deutschsprachigen Raum. Die Grundlage bildet die <span class="geocite-orange"><b>GEO</b></span><span class="geocite-blue"><i>prof</i></span>-Database. Bei dieser Datenbank handelt es sich um die
          erste vollständige Zusammenstellung der Professor*innenschaft der deutschsprachigen Geographie
          (Deutschland, Österreich, deutschsprachige Schweiz). In ihr sind sämtliche Hochschullehrer*innen erfasst, die
          seit 1949 eine ordentliche oder außerordentliche Geographie-Professur an einer deutschsprachigen Universität
          bzw. an einer Hochschule mit Promotionsrecht innehatten oder -haben. Insgesamt umfasst die
          Datenbank 1,120 Professor*innen (Stand Sommersemester 2023) inklusive Informationen zu deren akademischer
          Biographie, beruflichen Stationen seit dem ersten Ruf sowie der Denomination der jeweiligen Professur
          (siehe dazu: Steinbrink et al. 2023). </p>
        <p>Die bibliometrischen Daten stammen zum einen aus der wissenschaftlichen Literaturdatenbank Scopus und zum
          zweiten aus Informationen aus eigenständig digitalisierten und aufbereiteten geographischen Fachartikeln.
          Insgesamt umfasst die <span class="geocite-orange"><b>GEO</b></span><span class="geocite-blue"><i>cite</i></span>-Datenbank Informationen aus 51,014 wissenschfatlichen Texten. Weitere Informationen
          zum Aufbau und er technischen Umsetzung von <span class="geocite-orange"><b>GEO</b></span><span class="geocite-blue"><i>cite</i></span> finden Sie in der Veröffentlichung von Birkeneder et al.
          (2022) und auf der Projektwebsite. </p>
      </div>

      <div class="mb-4">
        <h2><span class="geocite-orange"><b>GEO</b></span><span class="geocite-blue"><i>cite</i></span> <b><i>Online</i></b> – Work in Progress</h2>
        <p>Bitte beachten Sie, dass es sich um eine Beta-Version von <span class="geocite-orange"><b>GEO</b></span><span class="geocite-blue"><i>cite</i></span> <b><i>Online</i></b> handelt. Sollten Sie Fehler
          feststellen, freuen wir uns über entsprechende Hinweise. Für Anfragen oder weitere Informationen stehen wir
          Ihnen gerne zur Verfügung.</p>
        <p>Wir wünschen Ihnen viel Spaß bei der Nutzung von <span class="geocite-orange"><b>GEO</b></span><span class="geocite-blue"><i>cite</i></span> und hoffen, dass Sie spannende Einblicke und neue
          Erkenntnisse gewinnen.</p>
        <p>Sollten Sie <span class="geocite-orange"><b>GEO</b></span><span class="geocite-blue"><i>cite</i></span> <b><i>Online</i></b> und die bereitgestellten Daten für eigene Forschungen nutzen, empfehlen wir
          folgende Zitierweise:</p>
        <div class="d-flex justify-center mb-4">
          <v-card max-width="800">

            <v-app-bar

            >

              <v-spacer></v-spacer>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="clickRaw">
                    <v-icon>mdi-raw</v-icon>
                  </v-btn>
                </template>
                <span>Raw Citation</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="clickBibtex">
                    <v-icon>mdi-alpha-b-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>BibTeX</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="clickRis">
                    <v-icon>mdi-file-download</v-icon>
                  </v-btn>
                </template>
                <span>RIS file</span>
              </v-tooltip>
            </v-app-bar>

            <v-card-title>
              <v-icon left>
                mdi-format-quote-open
              </v-icon>
              Zitation
            </v-card-title>
            <v-card-text>{{textContent[content]}}</v-card-text>

          </v-card>
        </div>
      </div>

      <div class="mb-4">
        <h3>Die Passauer Geographische Netzwerkstatt</h3>
        <p>
          <i>Philipp Aufenvenne, Bastian Birkeneder, Christian Haase, Philipp Mayr und Malte Steinbrink (†) </i>
        </p>
      </div>

      <div class="mb-4">
        <h3>Kontakt:</h3>
        <p>Geographische Netzwerkstatt</p>
        <p><a href="mailto:geographische-netzwerkstatt@uni-passau.de">geographische-netzwerkstatt@uni-passau.de</a></p>
        <p><a href="tel:+498515092736">Tel.: +49 (0)851/509-2736</a></p>
      </div>


    </div>
  </div>
</template>

<script>


import axios from "axios";

export default {
  data() {
    return {
      content: "raw",
      textContent: {
        "raw": 'Steinbrink, M.; Aufenvenne. P.; Birkeneder, B.; Haase, C. (2023): GEOcite online. Visualisierung ' +
            'und Analyse von Zitationsnetzwerken der deutschsprachigen Geographie. URL: www.geocite.de',
        "bibtex": '@online{Steinbrink_Aufenvenne_Birkeneder_Haase_2023, title={GEOcite online. Visualisierung und' +
            ' Analyse von Zitationsnetzwerken der deutschsprachigen Geographie.}, url={www.geocite.de}, ' +
            'author={Steinbrink, Malte and Aufenvenne, Philipp and ' +
            'Birkeneder, Bastian and Haase, Christian}, year={2023}}'
      }
    }
  },
  methods: {
    clickRaw() {
      this.content = "raw"
    },
    clickBibtex() {
      this.content = "bibtex"
    },
    clickRis() {
      axios.get(window.location.origin + this.$route.path + "static/src/vue/dist/geocite.ris", { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'text/plain' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = "geocite.ris"
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
    },
  }

}
</script>

<style>
.home {
  max-width: 1024px;
  font-size: 1.15em;
}

.geocite-logo img {
  max-width: 400px;
  height: auto;
}

.geocite-trynow img {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.geocite-orange {
  color: #f99815;
}
.geocite-blue {
  color: #3e5297;
}
</style>
